import { createSlice } from '@reduxjs/toolkit'

export const SHOP_SLICE_NAME = 'shop';
const initialState = null
  
export const shopSlice = createSlice({
  name: SHOP_SLICE_NAME,
  initialState,
  reducers: {
    fetchedShop: (state = initialState, action) => {
        // console.log('shop:', type, payload)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state, 
        ...action.payload
        // shop: action.payload,
      }
    },
    removeShop: (state) => {
        // console.log('rem shop:', type, payload)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        shop: null,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { fetchedShop, removeShop } = shopSlice.actions

export default shopSlice.reducer